<template>
  <div class="page">
    <Navbar title="注册统计" type="SMP" />
    <div class="count">
      <van-row>
        <van-col :span="8">
          <div class="item fc-cyan bdb bdr">
            <div class="amount">{{ count.today }}</div>
            <div class="text">今日注册人数</div>
          </div>
        </van-col>
        <van-col :span="8">
          <div class="item fc-cyan bdb bdr">
            <div class="amount">{{ count.week }}</div>
            <div class="text">本周注册人数</div>
          </div>
        </van-col>
        <van-col :span="8">
          <div class="item fc-cyan bdb">
            <div class="amount">{{ count.month }}</div>
            <div class="text">本月注册人数</div>
          </div>
        </van-col>
      </van-row>
    </div>

    <van-row class="title">
      <van-col span="24">注册明细</van-col>
    </van-row>
    <van-list
      :finished="finishedShow"
      finished-text="没有更多了"
      class="visit"
      :immediate-check="false"
      @load="loadTaskList"
    >
      <template #default>
        <van-row
          class="item bdc-b1s-gray"
          v-for="user in userList"
          :key="user.code"
        >
          <van-col span="24">
            <van-row>
              <van-col span="24" class="name text-short"
                >手机号码：{{ user.phone }}</van-col
              >
            </van-row>
            <van-row>
              <van-col span="24" class="type"
                >注册时间：{{ user.createTime }}</van-col
              >
            </van-row>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <van-row class="no-record" v-if="userList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import Navbar from '../../module/common/Navbar.vue'
import { Search, Image, Popup, Icon, List, Field, Tag } from 'vant'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Image.name]: Image,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Field.name]: Field,
    [Tag.name]: Tag
  },
  data () {
    return {
      shareShow: false,
      loadingShow: false,
      model: '',
      page: { current: 0, size: 50, last: 0 },
      count: { today: 0, week: 0, month: 0 },
      userList: []
    }
  },
  mounted () {
    this.model = window.sessionStorage.getItem(this.SESSION_SELLER_MODEL)
    document.title = '访问统计'
    this.retrieveSellerUserCount()
    this.retrieveSellerUserList()
  },
  methods: {
    loadTaskList () {
      if (this.page.last >= this.page.current) {
        this.retrieveSellerUserList()
      } else {
        this.finishedShow = true
      }
    },
    async retrieveSellerUserCount () {
      var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: sc, config: 'TODAY,WEEK,MONTH' }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveSellerUserCount', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.count.today = res.data.today
        this.count.week = res.data.week
        this.count.month = res.data.month
      }
    },
    async retrieveSellerUserList () {
      this.loadingShow = true
      var sc = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: sc, current: this.page.current, size: this.page.size }
      const { data: res } = await this.$http.post(this.UMS_URL + '/customer/user/retrieveSellerUserList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.userList = res.data
        this.page.last = res.page.last
      }
      this.loadingShow = false
    },
    consultResult (code) {
      this.$router.push({ path: '/seller/consultResult', query: { code: code } })
    },
    initShare () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var title = '个人电子资料'
      var desc = '完善您的个人电子资料，便于工作、证书、上学等咨询服务'
      var link = 'https://moc.utopanet.com/member/person?profile=' + this.profileKey + '&referee=' + user
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    },
    back () {
      this.$router.push({ path: '/seller/own' })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 120px;
  .bdb {
    border-bottom: 1px solid #eee;
  }
  .bdr {
    border-right: 1px solid #eee;
  }
  .count {
    width: 98%;
    margin: 5px auto 0px auto;
    .item {
      padding: 10px 0px;
      width: 100%;
      margin: 0px auto;
      .text {
        font-size: 13px;
        height: 25px;
        line-height: 25px;
      }
      .amount {
        font-size: 16px;
        font-weight: 600;
        height: 25px;
        line-height: 25px;
      }
    }
  }
  .back-btn {
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
  }
  .title {
    height: 25px;
    line-height: 25px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    color: #fff;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
  }
  .visit {
    width: 96%;
    margin: 0px auto;
    .item {
      padding: 10px 10px;
      .name {
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        margin: 2px auto;
      }
      .type {
        font-size: 13px;
        text-align: left;
        margin: 2px auto;
      }
      .state {
        text-align: left;
        margin: 2px auto;
      }
      .result {
        text-align: left;
        margin: 2px auto;
      }
      .time {
        text-align: left;
        margin: 2px auto;
      }
      .operator {
        margin: 2px auto;
        padding-top: 10px;
        border-top: 1px dashed #eee;
        .btn {
          width: 80%;
        }
      }
    }
  }
  .shareImg {
    width: 90%;
  }
}
</style>
